
/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */


.mean-container{
	position: unset;
	width: 100%;
	background: #transparent;
}

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
	display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  display: inline-block;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 12px;
  width: 100%;
  z-index: 8;
}
.is-sticky .mean-container .mean-bar {
  top: -4px;
}
.is-sticky .mean-container a.meanmenu-reveal span {
  background: #fff none repeat scroll 0 0;
}
.is-sticky .mean-container a.meanmenu-reveal {
  color: #fff;
}
.mean-container a.meanmenu-reveal {
	color: #494949;
	cursor: pointer;
	display: flex;
	flex-flow: column wrap;
	font-family: Arial,Helvetica,sans-serif;
	font-weight: 700;
	height: 66px;
	justify-content: center;
	line-height: 66px;
	padding: 0 20px 0 0;
	position: absolute;
	right: 0;
	text-decoration: none;
	top: 0;
	width: 30px;
}
.oth-page .mean-container a.meanmenu-reveal {
  color: #ddd;
}
.mean-container a.meanmenu-reveal.meanclose {
    top: 23px;
    font-size: 25px !important;
}

.mean-container a.meanmenu-reveal span {
	display: block;
	background: #494949 none repeat scroll 0 0;
	height: 2px;
	margin-top: 5px;
	width:  100%;
}
.oth-page .mean-container a.meanmenu-reveal span {
	background: #ddd none repeat scroll 0 0;
}
.mean-container a.meanmenu-reveal span:first-child{
	margin-top: 0;
}

.mean-container .mean-nav {
  background: #f1f1f1 none repeat scroll 0 0;
  float: left;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 66px;
  max-height: 255px;
  overflow-y: auto;
  width: 100%;
}

.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}

.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
}

.mean-container .mean-nav ul li a {
	display: block;
	float: left;
	width: calc(100% - 60px);
	padding: 12px 30px;
	margin: 0;
	text-align: left;
	color: #333;
	border-top: 1px solid #383838;
	border-top: 1px solid rgba(0,0,0,0.04);
	text-decoration: none;
	text-transform: uppercase;
	font-size: 14px;
}

.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 12px 10%;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(0,0,0,0.1);
	opacity: 0.75;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 10px 15%;
}

.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255,255,255,0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
	margin-top: 1px;
	width: 26px;
	height: 30px;
	padding: 9px !important;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: rgba(255,255,255,0.1);
	border: none !important;
	border-left: 1px solid rgba(0,0,0,0.1) !important;
	border-bottom: 1px solid rgba(255,255,255,0.1) !important;
	line-height: 35px;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
	background: #e1e1e1;
}

.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}

.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}


.mean-remove {
	display: none !important;
}


.mean-container a.meanmenu-reveal.meanclose {
    top: 0;
    font-size: 25px !important;
}

/*Code New*/

.mean-bar {
  clear: both;
  overflow: hidden;
}

.menu-text {
  left: 0;
  position: absolute;
  top: 8px;
  z-index: 999;
}

.menu-text a{
  color: #fff !important;
  display: inline-block;
  font-size: 18px;
  line-height: 50px;
  padding-left: 20px;
  text-transform: uppercase;
}